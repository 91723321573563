import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { SiteMarketingMessaging as ISiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessagingBackground.module.scss';

export const SiteMarketingMessagingBackground = observer(({
	siteMarketingMessagingModel: {
		activeMessageTemplate,
	},
}: { siteMarketingMessagingModel: ISiteMarketingMessaging }) => {
	return (
		<div
			className={
				classNames(
					styles['site-marketing-messaging-background'],
					styles[`site-marketing-messaging-background-${activeMessageTemplate}`],
				)
			}
			data-qa="site-marketing-messaging-background"
		/>
	);
});
